import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='card photo-album-card {{if public \"public\"}} border-0' data-test-photo-album-card>\n  <div class='card-header card-header--overlay'>\n    <img\n      alt='{{album.title}}'\n      src='{{album.albumMediumUrl}}'\n      class='card-img-rounded'\n    />\n    \n    <div class='card-title-bar gradient-overlay-rounded'>\n      <div class='card-titles'>\n        <h2 class='card-title'>{{album.title}}</h2>\n        <h3 class='card-subtitle'>{{moment-format album.date 'DD-MM-YYYY'}}</h3>\n      </div>\n      {{#if (and album.amountOfTaggedPhotos (can 'show photo-tag'))}}\n        <span class='album-tag-counter badge bg-info bottom-0 end-0'>\n          <FaIcon @icon='tag' />\n          {{album.amountOfTaggedPhotos}}/{{album.amountOfPhotos}}\n        </span>\n      {{/if}}\n    </div>\n  </div>\n</div>", {"contents":"<div class='card photo-album-card {{if public \"public\"}} border-0' data-test-photo-album-card>\n  <div class='card-header card-header--overlay'>\n    <img\n      alt='{{album.title}}'\n      src='{{album.albumMediumUrl}}'\n      class='card-img-rounded'\n    />\n    \n    <div class='card-title-bar gradient-overlay-rounded'>\n      <div class='card-titles'>\n        <h2 class='card-title'>{{album.title}}</h2>\n        <h3 class='card-subtitle'>{{moment-format album.date 'DD-MM-YYYY'}}</h3>\n      </div>\n      {{#if (and album.amountOfTaggedPhotos (can 'show photo-tag'))}}\n        <span class='album-tag-counter badge bg-info bottom-0 end-0'>\n          <FaIcon @icon='tag' />\n          {{album.amountOfTaggedPhotos}}/{{album.amountOfPhotos}}\n        </span>\n      {{/if}}\n    </div>\n  </div>\n</div>","moduleName":"amber-ui/components/cards/photo-album-card.hbs","parseOptions":{"srcName":"amber-ui/components/cards/photo-album-card.hbs"}});
import Component from '@ember/component';

const PhotoAlbumCardComponent = Component.extend({});

PhotoAlbumCardComponent.reopenClass({
  positionalParams: ['album', 'public'],
});

export default PhotoAlbumCardComponent;
