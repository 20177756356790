import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"card\">\n  <div class='card-header mt-2'>\n    <div class='d-flex justify-content-between'>\n      <h5>Inschrijven</h5>\n      <span class='text-muted'>{{form-opened-label @form}}</span>\n    </div>\n  </div>\n  <div class='card-body'>\n    <form {{on 'submit' (prevent-default this.submitResponse)}}>\n      <Form::Response::ResponseForm @form={{@form}} @response={{@response}} />\n\n      <ModelForm::FormActions\n        @errors={{@form.errors}}\n        @errorMessage={{this.errorMessage}}\n        @submitButtonLabel='Inschrijven'\n        @inputLayout='vertical'\n      >\n        {{#if @form.questions}}\n          <LinkTo @route='activities' class='btn btn-default'>\n            Annuleren\n          </LinkTo>\n          Vragen met een * zijn verplicht\n        {{/if}}\n      </ModelForm::FormActions>\n    </form>\n  </div>\n</div>", {"contents":"<div class=\"card\">\n  <div class='card-header mt-2'>\n    <div class='d-flex justify-content-between'>\n      <h5>Inschrijven</h5>\n      <span class='text-muted'>{{form-opened-label @form}}</span>\n    </div>\n  </div>\n  <div class='card-body'>\n    <form {{on 'submit' (prevent-default this.submitResponse)}}>\n      <Form::Response::ResponseForm @form={{@form}} @response={{@response}} />\n\n      <ModelForm::FormActions\n        @errors={{@form.errors}}\n        @errorMessage={{this.errorMessage}}\n        @submitButtonLabel='Inschrijven'\n        @inputLayout='vertical'\n      >\n        {{#if @form.questions}}\n          <LinkTo @route='activities' class='btn btn-default'>\n            Annuleren\n          </LinkTo>\n          Vragen met een * zijn verplicht\n        {{/if}}\n      </ModelForm::FormActions>\n    </form>\n  </div>\n</div>","moduleName":"amber-ui/components/form/response/response-card.hbs","parseOptions":{"srcName":"amber-ui/components/form/response/response-card.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class FormResponseCardComponent extends Component {
  @service flashNotice;
  @tracked errorMessage = null;

  @action
  async submitResponse() {
    try {
      await this.args.response.saveWithAnswers();
      // The response is the first thing that is saved (in order to save answers), so currently the response is
      // always 'incomplete'. Furthermore, the form has a field 'amountOfResponses' which should be updated.
      // We now reload the response and the corresponding form.
      this.args.response.reload();
      this.args.form.reload();
      this.flashNotice.sendSuccess('Inschrijving opgeslagen');
    } catch (error) {
      this.errorMessage = error.message;
      if (
        error.payload?.errors &&
        error.payload.errors.isAny('source.pointer', '/data/attributes/user')
      ) {
        this.errorMessage =
          'Er is al een response gevonden, probeer eerst te refreshen, zie je dit formulier dan nog? Neem dan contact op met de ict-commissie.';
      }
    }
  }
}
