import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p class='card-text'>\n  <MdEditor @content={{content}} @textareaId='forum-post-new' @setContent={{@setContent}} />\n</p>\n<div class='card-footer'>\n  <div class='row justify-content-end'>\n    <a href='#' class='btn btn-default w-auto me-2' {{on 'click' cancel}}>Annuleren</a>\n    <a href='#' class='btn btn-secondary w-auto' {{on 'click' save}}>Opslaan</a>\n  </div>\n</div>", {"contents":"<p class='card-text'>\n  <MdEditor @content={{content}} @textareaId='forum-post-new' @setContent={{@setContent}} />\n</p>\n<div class='card-footer'>\n  <div class='row justify-content-end'>\n    <a href='#' class='btn btn-default w-auto me-2' {{on 'click' cancel}}>Annuleren</a>\n    <a href='#' class='btn btn-secondary w-auto' {{on 'click' save}}>Opslaan</a>\n  </div>\n</div>","moduleName":"amber-ui/components/forum/forum-post-new.hbs","parseOptions":{"srcName":"amber-ui/components/forum/forum-post-new.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

// todo: incorporate the model-save-util into components?
export default class ForumPostNewComponent extends Component {
  @service store;
  @service flashNotice;

  get content() {
    return this.args.content;
  }

  set content(content) {
    this.args.setContent(content);
  }

  @action
  async save() {
    let { content, thread } = this.args;
    await this.store
      .createRecord('forum/post', {
        message: content,
        thread,
      })
      .save();
    this.flashNotice.sendSuccess('Forumbericht toegevoegd!');
    this.content = '';
    this.args.onSubmit();
  }

  @action
  cancel() {
    this.content = '';
  }
}
